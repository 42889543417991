<template>
    <div class="page message-manage-page">
        <el-form class="search-form" v-model="searchData" label-position="right" label-width="60px" size="small" inline>
            <el-row>
                <el-col :span="8">
                    <el-form-item label="标题" prop="title">
                        <el-input v-model.trim="searchData.title" placeholder="请输入标题搜索"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="内容" prop="content">
                        <el-input v-model.trim="searchData.content" placeholder="请输入内容搜索"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="时间" prop="sendTime">
                        <el-date-picker
                            v-model="searchData.sendTime"
                            :picker-options="pickerOptions"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            range-separator="-"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24" style="text-align: center">
                    <el-button type="primary" @click="search" size="small">搜索</el-button>
                    <el-button type="info" @click="reset" size="small">重置</el-button>
                </el-col>
            </el-row>
        </el-form>
        <div class="table-tab-panel">
            <el-radio-group v-model="sta" size="small">
                <el-radio-button
                    v-for="tab in tableTabs"
                    :key="tab.value"
                    :label="tab.value"
                >{{ tab.label }}</el-radio-button>
            </el-radio-group>
            <el-checkbox v-model="isRead" border size="small">未读</el-checkbox>
            <div class="mark" @click="read(null, true)">标记为已读</div>
        </div>
        <div class="table-main-panel">
            <div class="table-query-box">
                <el-table class="query-table" :data="tableData" stripe style="width: 100%" height="100%" size="mini" @selection-change="selectionChange">
                    <el-table-column type="selection" width="50"></el-table-column>
                    <el-table-column type="index" width="50" label="No.">
                        <template slot-scope="scope">{{getIndex(scope.$index)}}</template>
                    </el-table-column>
                    <el-table-column prop="title" label="标题" :show-overflow-tooltip="true" width="120px"></el-table-column>
                    <el-table-column prop="content" label="内容" min-width="200px" :show-overflow-tooltip="true"  align="center">
                        <template slot-scope="scope">
                            <div v-html="scope.row.content"></div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="branName" label="发送方" min-width="160px" :show-overflow-tooltip="true" align="center"></el-table-column>
                    <el-table-column prop="fromUserStr" label="发送人" min-width="160px" :show-overflow-tooltip="true" align="center"></el-table-column>
                    <el-table-column prop="fromUserStr" label="收信人" min-width="160px" :show-overflow-tooltip="true" align="center"></el-table-column>
                    <el-table-column prop="sendTime" label="发送日期" min-width="160px" :show-overflow-tooltip="true" align="center"></el-table-column>
                    <el-table-column prop="isRead" label="状态" :show-overflow-tooltip="true" align="center">
                        <template slot-scope="scope">{{ tabMap.isRead[scope.row.isRead] }}</template>
                    </el-table-column>
                    <el-table-column label="操作" fixed="right" align="center" width="180px">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="viewDetail(scope.row)">查看详情</el-button>
                            <el-button type="text" size="small" @click="read(scope.row.messageId)" v-if="!scope.row.isRead">已读</el-button>
                            <el-button type="text" style="color: #ff0000" size="small" @click="deleteItem(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <el-pagination
                class="table-pagination"
                v-if="total > pageSize"
                background
                layout="prev, pager, next"
                :total="total"
                :current-page="pageIndex"
                @current-change="currentChange"
            ></el-pagination>
        </div>
        <el-dialog 
            class="detail-dialog"
            title="消息详情" 
            :visible="showDetail" 
            :show-close="false"
            append-to-body 
            width="540px"
        >
            <div class="title-wrap">
                <p class="detail-title">{{ detailInfo.title }}</p>
                <p class="tip">发送人: {{detailInfo.fromUserStr}}</p>
            </div>
            <el-scrollbar class="content-wrap" v-html="detailInfo.content"></el-scrollbar>
            <div class="footer">
                <el-button size="small" @click="close">关闭</el-button>
                <el-button type="primary" v-if="detailInfo.isRead === 0" size="small" @click="read(detailInfo.messageId)">已读</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showDetail: false,
            detailInfo: {},
            pageIndex: 1,
            pageSize: 10,
            total: 0,
            searchData: {},
            tableTabs: [
                { label: "全部", value: -1 },
                { label: "紧急", value: 3 },
                { label: "重要", value: 2 },
                { label: "普通", value: 1 }
            ],
            sta: -1,
            isRead: 0,
            pickerOptions: {
                disabledDate: time => {
                    if (time.getTime() > new Date().getTime()) {
                        return true;
                    } else {
                        return false;
                    }  
                }
            },
            tableData: [],
            tabMap: {
                isRead: {
                    0: "未读",
                    1: "已读"
                },
                priority: {
                    0: "普通",
                    1: "重要",
                    2: "紧急"
                }
            },
            loading: false,
            tableSelect: []
        }
    },
    mounted() {
        this.getData();
    },
    watch: {
        sta() {
            this.search();
        },
        isRead() {
            this.search();
        }
    },
    methods: {
        getIndex(index) {
            return (this.pageIndex - 1) * this.pageSize + index + 1;
        },
        getData() {
            this.loading = true;
            let param = {
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
                title: this.searchData.title,
                content: this.searchData.content,
                sendStartDate: this.searchData.sendTime ? this.searchData.sendTime[0] : "",
                sendEndDate: this.searchData.sendTime ? this.searchData.sendTime[1] : ""
            }
            this.sta !== -1 && (param.priority = this.sta);
            this.isRead && (param.isRead = Number(!this.isRead));
            this.$post("/shop/api/groupMsg/pageList", param).then(data => {
                this.loading = false;
                this.tableData = data.datas;
                this.total = data.recordCount;
            })
        },
        search() {
            this.pageIndex = 1;
            this.getData();
        },
        reset() {
            this.searchData = {};
            this.search();
        },
        viewDetail(row) {
            this.$post("/shop/api/groupMsg/getDetail", { messageId: row.messageId }).then(data => {
                this.detailInfo = data;
                this.showDetail = true;
            })
        },
        deleteItem(row) {
            
            this.$confirm(`确定删除消息-"${row.title}"?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$post("/shop/api/groupMsg/delete", {messageId: row.messageId}).then(data => {
                    this.$message({
                        message: "删除成功",
                        type: "success"
                    })
                    this.search();
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        close() {
            this.showDetail = false;
            this.detailInfo = {};
        },
        read(id, multi = false) {
            let msgIds = [];
            if (multi) {
                this.tableSelect.forEach(item => {
                    msgIds.push(item.messageId);
                });
            } else {
                msgIds.push(id);
            }
            if (msgIds.length < 1) {
                this.$message({
                    message: "请选择消息",
                    type: "warning"
                });
                return;
            }
            this.$post("/shop/api/groupMsg/exitBatchReadState", {
                msgIds
            }).then(data => {
                this.$message({
                    message: "消息已读",
                    type: "success"
                });
                this.tableSelect = [];
                this.close();
                this.search();
            })
        },
        currentChange(cur) {
            this.pageIndex = cur;
            this.getData();
        },
        selectionChange(selection) {
            this.tableSelect = selection;
        },
        markRead() {}
    }
}
</script>

<style lang="scss">
.mark {
    width: 84px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    font-size: 12px;
    color: #606266;
    border: 1px solid #d5d5d5;
    border-radius: 4px;
    margin-left: 10px;
    cursor: pointer;
}
.detail-dialog {
    .el-dialog {
        border-radius: 16px;
    }
    .el-dialog__header {
        padding: 0;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        border-bottom: 1px solid #EDF0F4;
        color: #333;
    }
    .el-dialog__body {
        padding: 10px 20px;
    }
    .title-wrap {
        font-size: 12px;
        padding-bottom: 16px;
        border-bottom: 1px solid #edf0f4;
        .detail-title {
            // white-space: nowrap;
            // overflow: hidden;
            // text-overflow: ellipsis;
            font-size: 14px;
            // height: 24px;
            line-height: 24px;
            color: #333;
        }
        .tip {
            font-size: 12px;
            height: 20px;
            line-height: 20px;
            color: #606266;
        }
    }
    .content-wrap {
        margin-top: 6px;
        font-size: 12px;
        line-height: 22px;
        height: 200px;
        color: #606266;
        .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    .bottom-tip {
        margin: 20px 0;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .date-time {
            color: #616266;
        }
        .sender {
            color: #333;
        }
    }
    .footer {
        text-align: right;
        padding-top: 16px;
        border-top: 1px solid #edf0f4;
    }
}
</style>